<template>
  <div class="about"
       id="view_warp">
    <transition mode="out-in">
      <router-view></router-view>
    </transition>

  </div>
</template>


<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
.v-enter-active,
.v-leave-active {
  transition: all 0.5s ease;
}
.v-enter,
.v-leave-to {
  opacity: 0;
}
.about {
  width: 100%;
  height: 100%;
  position: relative;
}
</style>
